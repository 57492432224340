import * as React from 'react';
import { createIngestedDatasetDetailPath } from 'src/components/ingestion/common/navigation';
import { iTethysRouteProps } from 'src/components/ingestion/common/types';
import { UX_WRITING } from 'src/components/ingestion/common/uxWriting';
import { BreadcrumbGroup } from '@amzn/awsui-components-react';
import { WEBSITE_NAME, NONE } from '../commons/constants';
import {
  createPublisherTableLink,
  createResourceGroupLink,
  createBootstrapActionLink,
  createTemplateLink,
  createAdvisoryDetailsLink,
  Page,
} from '../routes/Paths';

const DATA_INGESTION_TITLE = 'Data ingestion';

export const browseCatalogs = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse catalogs',
    href: NONE,
  },
];
export const catalogDetails = (name) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse catalogs',
    href: Page.BROWSE_CATALOGS,
  },
  {
    text: name,
    href: NONE,
  },
];

export const browseDatabases = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse databases',
    href: NONE,
  },
];
export const databaseDetails = (name) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse databases',
    href: Page.BROWSE_DATABASES,
  },
  {
    text: name,
    href: NONE,
  },
];

export const browseDatasets = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse datasets',
    href: NONE,
  },
];
export const datasetDetails = (name) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse datasets',
    href: Page.BROWSE_DATASETS,
  },
  {
    text: name,
    href: NONE,
  },
];

export const myConnections = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Connections',
    href: NONE,
  },
];
export const createConnection = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Connections',
    href: Page.CONNECTIONS,
  },
  {
    text: 'Create connection',
    href: NONE,
  },
];
export const connectionDetails = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Connections',
    href: Page.CONNECTIONS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const mySubscriptions = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Subscriptions',
    href: NONE,
  },
];
export const createSubscription = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Subscriptions',
    href: Page.SUBSCRIPTIONS,
  },
  {
    text: 'Create subscription',
    href: NONE,
  },
];
export const subscriptionDetails = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Subscriptions',
    href: Page.SUBSCRIPTIONS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const publish = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Publish',
    href: Page.PUBLISH,
  },
];

export const publisherAccounts = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Published accounts',
    href: NONE,
  },
];
export const publisherRegisterAccount = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Published accounts',
    href: Page.PUBLISHER_ACCOUNTS,
  },
  {
    text: 'Register account',
    href: NONE,
  },
];
export const publisherAccountDetails = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Published accounts',
    href: Page.PUBLISHER_ACCOUNTS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const publisherTables = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: DATA_INGESTION_TITLE,
    href: NONE,
  },
];
export const publisherRegisterTable = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: DATA_INGESTION_TITLE,
    href: Page.PUBLISHER_TABLES,
  },
  {
    text: 'Register table',
    href: NONE,
  },
];
export const publisherCreateBackfill = (tableName, url) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: DATA_INGESTION_TITLE,
    href: Page.PUBLISHER_TABLES,
  },
  {
    text: tableName,
    href: url,
  },
  {
    text: 'Create backfill',
    href: NONE,
  },
];
export const publisherDedupe = (tableName, url) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: DATA_INGESTION_TITLE,
    href: Page.PUBLISHER_TABLES,
  },
  {
    text: tableName,
    href: url,
  },
  {
    text: 'Dedupe',
    href: NONE,
  },
];
export const publisherTableDetails = (tableName) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: DATA_INGESTION_TITLE,
    href: Page.PUBLISHER_TABLES,
  },
  {
    text: tableName,
    href: NONE,
  },
];

export const createLakeFormationPermission = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'New access requests',
    href: Page.MY_DATASETS,
  },
  {
    text: 'Create Lake Formation permission',
    href: NONE,
  },
];
export const permissionRequestDetails = (name) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'New access requests',
    href: Page.REQUESTS,
  },
  {
    text: name,
    href: NONE,
  },
];

export const myDatasets = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Datasets',
    href: NONE,
  },
];
export const myDatasetsDetails = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Datasets',
    href: Page.MY_DATASETS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const myDependencies = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Dependencies',
    href: NONE,
  },
];
export const createDependency = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Dependencies',
    href: Page.DEPENDENCIES,
  },
  {
    text: 'Create dependency',
    href: NONE,
  },
];
export const dependencyDetails = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Dependencies',
    href: Page.DEPENDENCIES,
  },
  {
    text: id,
    href: NONE,
  },
];
export const myRequests = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'New access requests',
    href: NONE,
  },
];

export const myBaselining = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Baseline',
    href: NONE,
  },
];

export const baselineDataAccessPermission = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Baseline',
    href: Page.BASELINING,
  },
  {
    text: 'Data access permission',
    href: NONE,
  },
];

export const baselineMembership = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Baseline',
    href: Page.BASELINING,
  },
  {
    text: 'Owner',
    href: NONE,
  },
];

export const listAdvisoriesBreadcrumbs = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisories',
    href: NONE,
  },
];

export const createAdvisoryBreadcrumbs = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisories',
    href: Page.LIST_ADVISORIES,
  },
  {
    text: 'Create',
    href: NONE,
  },
];

export const createAdvisoryFromTemplateBreadcrumbs = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisories',
    href: Page.LIST_ADVISORIES,
  },
  {
    text: 'Create',
    href: NONE,
  },
];

export const createAdvisoryFromAdvisoryBreadcrumbs = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisories',
    href: Page.LIST_ADVISORIES,
  },
  {
    text: 'Create',
    href: NONE,
  },
];

export const editAdvisoryBreadcrumbs = (id: string) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisories',
    href: Page.LIST_ADVISORIES,
  },
  {
    text: id,
    href: createAdvisoryDetailsLink(id),
  },
  {
    text: 'Edit',
    href: NONE,
  },
];

export const sendAdvisoryNotificationBreadcrumbs = (id: string) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisories',
    href: Page.LIST_ADVISORIES,
  },
  {
    text: id,
    href: createAdvisoryDetailsLink(id),
  },
  {
    text: 'Send notification',
    href: NONE,
  },
];

export const listAdvisoryTemplatesBreadcrumbs = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisory templates',
    href: NONE,
  },
];

export const editAdvisoryTemplateBreadcrumbs = (id: string) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisory templates',
    href: Page.LIST_ADVISORY_TEMPLATES,
  },
  {
    text: id,
    href: createAdvisoryDetailsLink(id),
  },
  {
    text: 'Edit',
    href: NONE,
  },
];

export const advisoryTemplateDetailsBreadcrumbs = (id: string) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisory templates',
    href: Page.LIST_ADVISORY_TEMPLATES,
  },
  {
    text: id,
    href: NONE,
  },
];

export const advisoryDetailsBreadcrumbs = (id: string) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data advisories',
    href: Page.LIST_ADVISORIES,
  },
  {
    text: id,
    href: createAdvisoryDetailsLink(id),
  },
];

export const myResourceGroups = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Resource groups',
    href: NONE,
  },
];
export const reosurcegroupFormNew = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Create resource group',
    href: NONE,
  },
];
export const reosurcegroupFormUpdate = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: id,
    href: createResourceGroupLink(id),
  },
  {
    text: 'Edit',
    href: NONE,
  },
];
export const resourcegroupDetails = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: id,
    href: NONE,
  },
];
export const resourceDetails = (rgid, id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: rgid,
    href: createResourceGroupLink(rgid),
  },
  {
    text: 'Resources',
    href: createResourceGroupLink(rgid),
  },
  {
    text: id,
    href: NONE,
  },
];

export const eventSubscriptions = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Event subscriptions',
    href: NONE,
  },
];

export const eventSubscriptionsDetails = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Event subscriptions',
    href: Page.EVENT_SUBSCRIPTIONS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const myBootstrapActions = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Bootstrap actions',
    href: NONE,
  },
];

export const bootstrapactionFormNew = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Bootstrap actions',
    href: Page.BOOTSTRAPACTIONS,
  },
  {
    text: 'Create bootstrap action',
    href: NONE,
  },
];
export const bootstrapactionFormUpdate = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Bootstrap actions',
    href: Page.BOOTSTRAPACTIONS,
  },
  {
    text: id,
    href: createBootstrapActionLink(id),
  },
  {
    text: 'Edit',
    href: NONE,
  },
];
export const bootstrapactionDetails = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Bootstrap actions',
    href: Page.BOOTSTRAPACTIONS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const templateNew = () => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Templates',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Create template',
    href: NONE,
  },
];
export const templateUpdate = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Templates',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: id,
    href: createTemplateLink(id),
  },
  {
    text: 'Edit',
    href: NONE,
  },
];
export const templateDetails = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Resource groups',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: 'Templates',
    href: Page.RESOURCEGROUPS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const createNewGroup = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Groups',
    href: Page.GROUP,
  },
  {
    text: 'Create new group',
    href: NONE,
  },
];
export const myGroup = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'My group',
    href: Page.GROUP,
  },
];

export const myGroups = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Groups',
    href: NONE,
  },
  {
    text: id,
    href: NONE,
  },
];

export const workspace_tag_accesses = (_) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Existing workspace access',
    href: Page.WORKSPACEACCESS,
  },
];

export const createTag = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'My permissions tags',
    href: Page.TAGS,
  },
  {
    text: 'Create tag',
    href: NONE,
  },
];

export const editTag = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'My permissions tags',
    href: Page.TAGS,
  },
  {
    text: 'Edit tag',
    href: NONE,
  },
];

export const tags = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'My permissions tags',
    href: Page.TAGS,
  },
];

export const myNotifications = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Notifications',
    href: NONE,
  },
];
export const createNotification = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Create notification',
    href: NONE,
  },
];
export const notificationDetail = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification details',
    href: NONE,
  },
];
export const notificationBatchDetail = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification batch detail',
    href: NONE,
  },
];

export const notificationTemplates = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification templates',
    href: NONE,
  },
];
export const notificationConfiguration = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification settings',
    href: NONE,
  },
];
export const createNotificationTemplate = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification templates',
    href: Page.NOTIFICATION_TEMPLATES,
  },
  {
    text: 'Create notification template',
    href: NONE,
  },
];
export const updateNotificationTemplate = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification templates',
    href: Page.NOTIFICATION_TEMPLATES,
  },
  {
    text: 'Edit',
    href: NONE,
  },
];

export const notificationTemplateDetail = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Notifications',
    href: Page.NOTIFICATIONS,
  },
  {
    text: 'Notification template',
    href: Page.NOTIFICATION_TEMPLATES,
  },
  {
    text: 'Notification template details',
    href: NONE,
  },
];

export const browseWorkspaces = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse workspaces',
    href: NONE,
  },
];

export const createWorkspace = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Workspaces',
    href: Page.BROWSE_WORKSPACES,
  },
  {
    text: 'Create workspace',
    href: NONE,
  },
];

export const consumedPermissions = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data access permissions',
    href: Page.WORKSPACE_ALL_PERMISSIONS,
  },
];

export const workspaceDetail = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Workspaces',
    href: Page.BROWSE_WORKSPACES,
  },
  {
    text: id,
    href: NONE,
  },
];

export const currentWorkspace = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: id,
    href: NONE,
  },
];

export const updateWorkspace = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Workspaces',
    href: Page.BROWSE_WORKSPACES,
  },
  {
    text: 'Edit ' + id,
    href: NONE,
  },
];

export const wsBrowseDatasets = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse workspace datasets',
    href: NONE,
  },
];

export const searchData = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Search data',
    href: NONE,
  },
];

export const wsMyRequests = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse workspace access requests',
    href: NONE,
  },
];

export const wsMyRequestsDetail = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse workspace access requests',
    href: Page.WORKSPACE_MY_REQUESTS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const wsBrowseDatabases = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse workspace databases',
    href: NONE,
  },
];

export const wsBrowseCatalogs = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse workspace catalogs',
    href: NONE,
  },
];

export const wsDataPublish = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Publish data',
    href: NONE,
  },
];

export const wsGlueRegister = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Register glue data',
    href: NONE,
  },
];

export const wsRedshiftRegister = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Register redshift data',
    href: NONE,
  },
];

export const wsTagRegister = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Register tag',
    href: NONE,
  },
];

export const wsResourceLinks = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Athena query',
    href: NONE,
  },
];

export const wsRedshiftQuery = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Redshift query',
    href: NONE,
  },
];

export const wsPermissionDetail = (name) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Data access permissions',
    href: Page.WORKSPACE_ALL_PERMISSIONS,
  },
  {
    text: name,
    href: NONE,
  },
];

export const schemaDetails = (schema) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse databases',
    href: Page.WORKSPACES_BROWSE_DATABASES,
  },
  {
    text: schema,
    href: NONE,
  },
];

export const wsDatasetDetails = (name) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Browse workspace datasets',
    href: Page.WORKSPACES_BROWSE_DATASETS,
  },
  {
    text: name,
    href: NONE,
  },
];

export const wsTagDetails = (tagid) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Tags',
    href: NONE,
  },
  {
    text: `${tagid.split(':')[0]}:${tagid.split(':')[1]}`,
    href: NONE,
  },
];

export const wsDetails = (name) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Workspaces',
    href: NONE,
  },
  {
    text: name,
    href: NONE,
  },
];
export const templates = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Templates',
    href: NONE,
  },
];
export const create_template = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Templates',
    href: Page.TEMPLATES,
  },
  {
    text: 'Create new template',
    href: NONE,
  },
];
export const template_view = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Templates',
    href: Page.TEMPLATES,
  },
  {
    text: id,
    href: NONE,
  },
];
export const edit_template = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Templates',
    href: Page.TEMPLATES,
  },
  {
    text: 'Edit template',
    href: NONE,
  },
];

export const fineGrainAccessPolicies = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Fine grain access policies',
    href: NONE,
  },
];
export const createFgaPolicy = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Fine grain access policies',
    href: Page.FINE_GRAIN_ACCESS_POLICIES,
  },
  {
    text: 'Create access policy',
    href: NONE,
  },
];
export const editFgaPolicy = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Fine grain access policies',
    href: Page.FINE_GRAIN_ACCESS_POLICIES,
  },
  {
    text: 'Edit access policy',
    href: NONE,
  },
];
export const viewFgaPolicy = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Fine grain access policies',
    href: Page.FINE_GRAIN_ACCESS_POLICIES,
  },
  {
    text: id,
    href: NONE,
  },
];

export const metadataForms = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metadata forms',
    href: NONE,
  },
];
export const createMetadataForm = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metadata forms',
    href: Page.METADATA_FORMS,
  },
  {
    text: 'Create form',
    href: NONE,
  },
];
export const editMetadataForm = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metadata forms',
    href: Page.METADATA_FORMS,
  },
  {
    text: 'Edit form',
    href: NONE,
  },
];
export const viewMetadataForm = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metadata forms',
    href: Page.METADATA_FORMS,
  },
  {
    text: id,
    href: NONE,
  },
];

export const businessGlossaries = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Business glossaries',
    href: NONE,
  },
];
export const createBusinessGlossary = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Business glossaries',
    href: Page.BUSINESS_GLOSSARIES,
  },
  {
    text: 'Create glossary',
    href: NONE,
  },
];
export const editBusinessGlossary = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Business glossaries',
    href: Page.BUSINESS_GLOSSARIES,
  },
  {
    text: 'Edit glossary',
    href: NONE,
  },
];
export const viewBusinessGlossary = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Business glossaries',
    href: Page.BUSINESS_GLOSSARIES,
  },
  {
    text: id,
    href: NONE,
  },
];
export const dataZoneManage = () => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Manage DataZone resources',
    href: Page.DATAZONE_MANAGE,
  },
];
export const dataZoneOnboard = () => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Manage DataZone resources',
    href: Page.DATAZONE_MANAGE,
  },
  {
    text: 'Onboard',
    href: NONE,
  },
];

export const BreadcrumbsBusinessGlossaries = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={businessGlossaries} label='Breadcrumbs' />
);
export const BreadcrumbsCreateBusinessGlossary = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createBusinessGlossary} label='Breadcrumbs' />
);
export const BreadcrumbsViewBusinessGlossary = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={viewBusinessGlossary(props.match.params.id)} label='Breadcrumbs' />
);
export const BreadcrumbsEditBusinessGlossary = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={editBusinessGlossary} label='Breadcrumbs' />
);

export const BreadcrumbsMetadataForms = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={metadataForms} label='Breadcrumbs' />
);
export const BreadcrumbsCreateMetadataForm = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createMetadataForm} label='Breadcrumbs' />
);
export const BreadcrumbsViewMetadataForm = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={viewMetadataForm(props.match.params.id)} label='Breadcrumbs' />
);
export const BreadcrumbsEditMetadataForm = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={editMetadataForm} label='Breadcrumbs' />
);

export const BreadcrumbsFgaPolicies = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={fineGrainAccessPolicies} label='Breadcrumbs' />
);
export const BreadcrumbsCreateFgaPolicy = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createFgaPolicy} label='Breadcrumbs' />
);
export const BreadcrumbsViewFgaPolicy = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={viewFgaPolicy(props.match.params.id)} label='Breadcrumbs' />
);
export const BreadcrumbsEditFgaPolicy = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={editFgaPolicy} label='Breadcrumbs' />
);

export const BreadcrumbsBrowseCatalogs = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={browseCatalogs} label='Breadcrumbs' />
);
export const BreadcrumbsCatalogDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={catalogDetails(props.activeCatalogName)} label='Breadcrumbs' />
);

export const BreadcrumbsBrowseDatabases = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={browseDatabases} label='Breadcrumbs' />
);
export const BreadcrumbsDatabaseDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={databaseDetails(props.activeDatabaseName)} label='Breadcrumbs' />
);

export const BreadcrumbsBrowseDatasets = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={browseDatasets} label='Breadcrumbs' />
);
export const BreadcrumbsDatasetDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={datasetDetails(props.activeDatasetName)} label='Breadcrumbs' />
);

export const BreadcrumbsMyConnections = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={myConnections} label='Breadcrumbs' />
);
export const BreadcrumbsCreateConnection = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createConnection} label='Breadcrumbs' />
);
export const BreadcrumbsConnectionDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={connectionDetails(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsMySubscriptions = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={mySubscriptions} label='Breadcrumbs' />
);
export const BreadcrumbsCreateSubscription = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createSubscription} label='Breadcrumbs' />
);
export const BreadcrumbsSubscriptionDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={subscriptionDetails(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsPublish = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={publish} label='Breadcrumbs' />
);

export const BreadcrumbsPublishedTables = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={publisherTables} label='Breadcrumbs' />
);
export const BreadcrumbsPublishRegisterTable = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={publisherRegisterTable} label='Breadcrumbs' />
);
export const BreadcrumbsCreateBackfill = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherCreateBackfill(
      props.match.params.TableName,
      createPublisherTableLink(props.match.params.TableId, props.match.params.TableName),
    )}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsPostDedupe = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherDedupe(
      props.match.params.TableName,
      createPublisherTableLink(props.match.params.TableId, props.match.params.TableName),
    )}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsPublishTableDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherTableDetails(props.match.params.TableName)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsPublisherAccounts = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={publisherAccounts} label='Breadcrumbs' />
);
export const BreadcrumbsPublisherRegisterAccount = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={publisherRegisterAccount} label='Breadcrumbs' />
);
export const BreadcrumbsPublishAccountDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={publisherAccountDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsCreateLakeFormationPermission = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createLakeFormationPermission} label='Breadcrumbs' />
);
export const BreadcrumbsPermissionRequestDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={permissionRequestDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsPermissionDetail = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={wsPermissionDetail(props.match.params.permissionId)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyDatasets = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={myDatasets} label='Breadcrumbs' />
);
export const BreadcrumbsMyDatasetsDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={myDatasetsDetails(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsMyDependencies = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={myDependencies} label='Breadcrumbs' />
);
export const BreadcrumbsCreateDependency = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createDependency} label='Breadcrumbs' />
);
export const BreadcrumbsDependencyDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={dependencyDetails(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsCreateNewGroup = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createNewGroup} label='Breadcrumbs' />
);
export const BreadcrumbsMyGroup = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={myGroup} label='Breadcrumbs' />
);

export const BreadcrumbsMyGroups = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={myGroups(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadCrumbsWorkspaceTagAccess = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={workspace_tag_accesses(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsTags = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={tags} label='Breadcrumbs' />
);

export const BreadcrumbsCreateTag = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createTag} label='Breadcrumbs' />
);

export const BreadcrumbsEditTag = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={editTag} label='Breadcrumbs' />
);

export const BreadcrumbsMyRequests = () => <BreadcrumbGroup items={myRequests} label='Breadcrumbs' />;

export const BreadcrumbsMyBaselining = () => <BreadcrumbGroup items={myBaselining} label='Breadcrumbs' />;

export const BreadcrumbsBaselineDataAccessPermission = () => (
  <BreadcrumbGroup items={baselineDataAccessPermission} label='Breadcrumbs' />
);

export const BreadcrumbsBaselineMembership = () => <BreadcrumbGroup items={baselineMembership} label='Breadcrumbs' />;

export const BreadcrumbsListAdvisories = () => (
  <BreadcrumbGroup items={listAdvisoriesBreadcrumbs} label='Breadcrumbs' />
);

export const BreadcrumbsCreateAdvisory = () => (
  <BreadcrumbGroup items={createAdvisoryBreadcrumbs} label='Breadcrumbs' />
);

export const BreadcrumbsCreateAdvisoryFromTemplate = () => (
  <BreadcrumbGroup items={createAdvisoryFromTemplateBreadcrumbs} label='Breadcrumbs' />
);

export const BreadcrumbsCreateAdvisoryFromAdvisory = () => (
  <BreadcrumbGroup items={createAdvisoryFromAdvisoryBreadcrumbs} label='Breadcrumbs' />
);

export const BreadcrumbsEditAdvisory = (props) => (
  <BreadcrumbGroup items={editAdvisoryBreadcrumbs(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsSendAdvisoryNotification = (props) => (
  <BreadcrumbGroup items={sendAdvisoryNotificationBreadcrumbs(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsListAdvisoryTemplates = () => (
  <BreadcrumbGroup items={listAdvisoryTemplatesBreadcrumbs} label='Breadcrumbs' />
);

export const BreadcrumbsEditAdvisoryTemplate = (props) => (
  <BreadcrumbGroup items={editAdvisoryTemplateBreadcrumbs(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsAdvisoryTemplateDetail = (props) => (
  <BreadcrumbGroup items={advisoryTemplateDetailsBreadcrumbs(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsAdvisoryDetail = (props) => (
  <BreadcrumbGroup items={advisoryDetailsBreadcrumbs(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsMyNotifications = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={myNotifications} label='Breadcrumbs' />
);
export const BreadcrumbsCreateNotifications = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createNotification} label='Breadcrumbs' />
);
export const BreadcrumbsNotificationDetail = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={notificationDetail} label='Breadcrumbs' />
);
export const BreadcrumbsNotificationBatchDetail = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={notificationBatchDetail} label='Breadcrumbs' />
);

export const BreadcrumbsNotificationTemplates = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={notificationTemplates} label='Breadcrumbs' />
);
export const BreadcrumbsCreateNotificationTemplate = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createNotificationTemplate} label='Breadcrumbs' />
);
export const BreadcrumbsUpdateNotificationTemplate = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={updateNotificationTemplate} label='Breadcrumbs' />
);
export const BreadcrumbsNotificationTemplateDetail = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={notificationTemplateDetail} label='Breadcrumbs' />
);

export const BreadcrumbsNotificationConfiguration = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={notificationConfiguration} label='Breadcrumbs' />
);

export const BreadcrumbsMyResourceGroups = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={myResourceGroups} label='Breadcrumbs' />
);
export const BreadcrumbsResourceGroupFormNew = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={reosurcegroupFormNew} label='Breadcrumbs' />
);
export const BreadcrumbsResourceGroupFormUpdate = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={reosurcegroupFormUpdate(props.match.params.id)}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsResourceGroupDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={resourcegroupDetails(props.match.params.id)} label='Breadcrumbs' />
);
export const BreadcrumbsResourceDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={resourceDetails(props.match.params.rgid, props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsEventSubscriptions = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={eventSubscriptions} label='Breadcrumbs' />
);
export const BreadcrumbsEventSubscriptionDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={eventSubscriptionsDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsMyBootstrapActions = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={myBootstrapActions} label='Breadcrumbs' />
);
export const BreadcrumbsBootstrapActionFormNew = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={bootstrapactionFormNew} label='Breadcrumbs' />
);
export const BreadcrumbsBootstrapActionFormUpdate = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={bootstrapactionFormUpdate(props.match.params.id)}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsBootstrapActionDetails = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={bootstrapactionDetails(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsTemplateNew = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={templateNew()} label='Breadcrumbs' />
);
export const BreadcrumbsTemplateUpdate = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={templateUpdate(props.match.params.id)} label='Breadcrumbs' />
);
export const BreadcrumbsTemplateDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={templateDetails(props.match.params.id)} label='Breadcrumbs' />
);
export const BreadcrumbsCreateWorkspace = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={createWorkspace} label='Breadcrumbs' />
);
export const BreadcrumbsBrowseWorkspaces = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={browseWorkspaces} label='Breadcrumbs' />
);
export const BreadcrumbsWorkspaceDetail = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={workspaceDetail(props.match.params.workspaceId)}
    label='Breadcrumbs'
  />
);
export const BreadcrumbsUpdateWorkspace = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={updateWorkspace(props.match.params.workspaceId)}
    label='Breadcrumbs'
  />
);

export const BreadcrumbsWorkspaceMyRequests = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsMyRequests} label='Breadcrumbs' />
);

export const BreadcrumbsWorkspaceMyRequestsDetail = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsMyRequestsDetail(props.match.params.id)} label='Breadcrumbs' />
);

export const BreadcrumbsConsumedPermissions = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={consumedPermissions} label='Breadcrumbs' />
);

export const BreadcrumbsSearchData = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={searchData} label='Breadcrumbs' />
);

export const BreadcrumbsSchemaDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={schemaDetails(props.activeDatabaseName)} label='Breadcrumbs' />
);

export const BreadcrumbsWSDatasetDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsDatasetDetails(props.activeDatasetName)} label='Breadcrumbs' />
);

export const BreadcrumbsWSTagDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsTagDetails(props.match.params.tagid)} label='Breadcrumbs' />
);

export const BreadcrumbsWSDetails = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsDetails(props.match.params.workspaceId)} label='Breadcrumbs' />
);

export const BreadcrumbsWSDataPublish = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsDataPublish} label='Breadcrumbs' />
);

export const BreadcrumbsWSGlueRegister = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsGlueRegister} label='Breadcrumbs' />
);

export const BreadcrumbsWSRedshiftRegister = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsRedshiftRegister} label='Breadcrumbs' />
);

export const BreadcrumbsWSTagRegister = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsTagRegister} label='Breadcrumbs' />
);
export const BreadcrumbsTemplates = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={templates} label='Breadcrumbs' />
);
export const BreadcrumbsCreateTemplate = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={create_template} label='Breadcrumbs' />
);
export const BreadcrumbsTemplateView = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={template_view(props.match.params.templateId)} label='Breadcrumbs' />
);
export const BreadcrumbsEditTemplate = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={edit_template} label='Breadcrumbs' />
);
export const BreadcrumbsWSResourceLinks = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsResourceLinks} label='Breadcrumbs' />
);

export const BreadcrumbsWSRedshiftQuery = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={wsRedshiftQuery} label='Breadcrumbs' />
);

// TODO: we can remove hundreds of lines from this file by rewriting all the "export const"s using this helper:
export const createBreadcrumbs = (items) => {
  return (props) => <BreadcrumbGroup onFollow={props.onFollow} items={items} label='Breadcrumbs' />;
};
// these two are examples of it:
export const BreadcrumbsDataZoneManage = createBreadcrumbs(dataZoneManage());
export const BreadcrumbsDataZoneOnboard = createBreadcrumbs(dataZoneOnboard());

const ROOT_URL = {
  text: WEBSITE_NAME,
  href: Page.HOME,
};

const MANAGE_DATASETS = {
  text: DATA_INGESTION_TITLE,
  href: Page.PUBLISHER_TABLES,
};

const registerContractBreadcrumbs = [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: UX_WRITING.REGISTER_DATASET,
    href: Page.INGESTION.REGISTER_CONTRACT,
  },
];

export const BreadcrumbsRegisterContract = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={registerContractBreadcrumbs} label='Breadcrumbs' />
);

const DATASET_DETAIL = (routeParams: iTethysRouteProps) => ({
  text: routeParams.dataset,
  href: createIngestedDatasetDetailPath(routeParams),
});

const updateContractBreadcrumbs = (routeParams: iTethysRouteProps) => [
  ROOT_URL,
  MANAGE_DATASETS,
  DATASET_DETAIL(routeParams),
  {
    text: 'Update dataset',
    href: NONE,
  },
];

export const BreadcrumbsUpdateContract = ({ onFollow, match }) => (
  <BreadcrumbGroup onFollow={onFollow} items={updateContractBreadcrumbs(match.params)} label='Breadcrumbs' />
);

const contractDetailBreadcrumbs = (dataset: string) => [
  ROOT_URL,
  MANAGE_DATASETS,
  {
    text: dataset,
    href: NONE,
  },
];

export const BreadcrumbsContractDetail = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={contractDetailBreadcrumbs(props.match.params.dataset)}
    label='Breadcrumbs'
  />
);

const monitorDatasetBreadcrumbs = (routeParams: iTethysRouteProps) => [
  ROOT_URL,
  MANAGE_DATASETS,
  DATASET_DETAIL(routeParams),
  {
    text: 'Monitoring',
    href: NONE,
  },
];

export const BreadcrumbsMonitoringDataset = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={monitorDatasetBreadcrumbs(props.match.params)}
    label='Breadcrumbs'
  />
);

// MetricStore
export const metricCollectionsCatalog = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metric collections catalog',
    href: NONE,
  },
];

export const BreadcrumbsViewMetricCollectionsCatalog = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={metricCollectionsCatalog} label='Breadcrumbs' />
);

export const metricCollectionDefinition = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metric collections catalog',
    href: Page.METRIC_COLLECTIONS_CATALOG,
  },
  {
    text: id,
    href: NONE,
  },
];

export const BreadcrumbsMetricCollectionDefinition = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={metricCollectionDefinition(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const metricCollectionWeeklyView = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metric collections catalog',
    href: Page.METRIC_COLLECTIONS_CATALOG,
  },
  {
    text: 'Weekly view',
    href: NONE,
  },
  {
    text: id,
    href: NONE,
  },
];

export const BreadcrumbsMetricCollectionWeeklyView = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={metricCollectionWeeklyView(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const metricCollectionMonthlyView = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metric collections catalog',
    href: Page.METRIC_COLLECTIONS_CATALOG,
  },
  {
    text: 'Monthly view',
    href: NONE,
  },
  {
    text: id,
    href: NONE,
  },
];

export const BreadcrumbsMetricCollectionMonthlyView = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={metricCollectionMonthlyView(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const metricCollectionQuarterlyView = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metric collections catalog',
    href: Page.METRIC_COLLECTIONS_CATALOG,
  },
  {
    text: 'Quarterly view',
    href: NONE,
  },
  {
    text: id,
    href: NONE,
  },
];

export const BreadcrumbsMetricCollectionQuarterlyView = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={metricCollectionQuarterlyView(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const metricCollectionYearlyView = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metric collections catalog',
    href: Page.METRIC_COLLECTIONS_CATALOG,
  },
  {
    text: 'Yearly view',
    href: NONE,
  },
  {
    text: id,
    href: NONE,
  },
];

export const BreadcrumbsMetricCollectionYearlyView = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={metricCollectionYearlyView(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const metricsCatalog = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metrics catalog',
    href: NONE,
  },
];

export const BreadcrumbsViewMetricsCatalog = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={metricsCatalog} label='Breadcrumbs' />
);

export const metricsCatalogDefinition = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Metrics catalog',
    href: Page.METRICS_CATALOG,
  },
  {
    text: id,
    href: NONE,
  },
];

export const BreadcrumbsMetricsCatalogDefinition = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={metricsCatalogDefinition(props.match.params.id)}
    label='Breadcrumbs'
  />
);

export const reportingTablesCatalog = [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Reporting tables catalog',
    href: NONE,
  },
];

export const BreadcrumbsViewReportingTablesCatalog = (props) => (
  <BreadcrumbGroup onFollow={props.onFollow} items={reportingTablesCatalog} label='Breadcrumbs' />
);

export const reportingTableDefinition = (id) => [
  {
    text: WEBSITE_NAME,
    href: Page.HOME,
  },
  {
    text: 'Reporting tables catalog',
    href: Page.REPORTING_TABLE_CATALOG,
  },
  {
    text: id,
    href: NONE,
  },
];

export const BreadcrumbsReportingTableDefinition = (props) => (
  <BreadcrumbGroup
    onFollow={props.onFollow}
    items={reportingTableDefinition(props.match.params.id)}
    label='Breadcrumbs'
  />
);
