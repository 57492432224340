import {
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Grid,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { EmptyState } from 'src/commons/EmptyState';
import { PageHeader } from 'src/components/notifications/common';
import { defaultWrapLinesPreference, largePageSizePreference, paginationLabels } from 'src/commons/tables';
import {
  defaultEntityBoostedFields,
  defaultTermAggregations,
  entityDisplayName,
  flatten,
  getDataSources,
  getSearchTableColumnDefinitions,
  SearchAggregations,
  SearchEntityType,
} from 'src/components/search/constants';
import { searchData, useServerCollections } from 'src/components/search/server-hook';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { DefaultRouteProps } from 'src/commons/common';

export interface SearchHomeProps extends DefaultRouteProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  // Search entity objects list, from search API
  searchResult: any[];
  catalogMap: any;
  onAggregationsChange: (newAggregations: SearchAggregations) => void;
  searchQueryString: string;
  searchLoading: boolean;
  onSetSearchLoading: (isLoading: boolean) => void;
  onSearchQueryChange: (query: string) => void;
  searchFilters: {
    entityType: SearchEntityType;
    aggregations: SearchAggregations;
    filters: { [key: string]: string[] };
  };
}

export const SearchHome = (props: SearchHomeProps) => {
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 25,
  });
  const [filteringText, setFilteringText] = useState(props.searchQueryString);
  const [delayedFilteringText, setDelayedFilteringText] = useState(props.searchQueryString);
  const [totalItems, setTotalItems] = useState([]);
  const [nextToken, setNextToken] = useState(undefined);
  const dataSources: string[] = getDataSources(!!props.activeWorkspace);

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const { pageSize } = preferences;
  const params = {
    pagination: {
      pageSize,
    },
    queryString: delayedFilteringText,
    entityType: props.searchFilters.entityType,
    filters: props.searchFilters.filters,
    onAggregationsChange: props.onAggregationsChange,
    onSetSearchLoading: props.onSetSearchLoading,
    isWorkspace: !!props.activeWorkspace,
    catalogMap: props.catalogMap,
    nextToken: undefined,
    onNextTokenChange: (nextToken: string) => {
      setNextToken(nextToken);
    },
  };

  // Server side filtering
  const { items } = useServerCollections(params);
  // client side pagination handle
  const {
    items: pageItems,
    paginationProps,
    collectionProps,
  } = useCollection(totalItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  useEffect(() => {
    let currentItems = totalItems;
    currentItems.push(...items);
    setTotalItems(currentItems);
  }, [items]);

  useEffect(() => {
    // Reset items on query / entity type or filters change
    setTotalItems([]);
    setNextToken(undefined);
    props.onSearchQueryChange(delayedFilteringText);
  }, [props.searchFilters.entityType, delayedFilteringText, props.searchFilters.filters]);

  const onClearFilter = () => {
    setFilteringText('');
    setDelayedFilteringText('');
  };

  const onLoadNextResults = () => {
    // invoke search API with next token.
    const handleNextItemsSearch = async () => {
      let searchResult = await searchData(
        [props.searchFilters.entityType],
        delayedFilteringText,
        defaultEntityBoostedFields.get(props.searchFilters.entityType),
        pageSize,
        defaultTermAggregations.get(props.searchFilters.entityType),
        props.searchFilters.filters,
        nextToken,
        props.onSetSearchLoading,
      );
      let nextItems = flatten(searchResult.resultList, dataSources, props.searchFilters.entityType, props.catalogMap);
      let currentItems = totalItems;
      currentItems.push(...nextItems);
      setTotalItems(currentItems);
      setNextToken(searchResult.nextToken);
      if (searchResult.aggregations) props.onAggregationsChange(searchResult.aggregations);
    };
    handleNextItemsSearch();
  };

  const columnDefinitions = useMemo(() => {
    return getSearchTableColumnDefinitions(props.searchFilters.entityType, props.workspaceNameMap);
  }, [!!props.activeWorkspace, props.searchFilters.entityType]);

  let anyQueryFiltersState =
    !!delayedFilteringText ||
    (props.searchFilters.filters != undefined && Object.keys(props.searchFilters.filters).length > 0);
  let emptyTableDisplayString = anyQueryFiltersState ? 'No entities found!' : 'Search with prefix or full match';

  return (
    <Table
      {...collectionProps}
      loadingText={`Loading ${entityDisplayName.get(props.searchFilters.entityType)}...`}
      loading={props.searchLoading}
      columnDefinitions={columnDefinitions}
      items={pageItems}
      wrapLines={preferences.wrapLines}
      resizableColumns={true}
      empty={
        <EmptyState
          title='No matches'
          subtitle={emptyTableDisplayString}
          action={anyQueryFiltersState && <Button onClick={onClearFilter}>Clear filters</Button>}
        />
      }
      header={
        <PageHeader
          buttons={[]}
          header={
            <>
              {entityDisplayName.get(props.searchFilters.entityType)}
              <span className='awsui-util-header-counter'>{` (${totalItems.length})`}</span>
            </>
          }
        />
      }
      filter={
        <Grid gridDefinition={[{ colspan: 5 }, { colspan: 5 }]}>
          <TextFilter
            filteringText={filteringText}
            onChange={({ detail }) => setFilteringText(detail.filteringText)}
            onDelayedChange={() => setDelayedFilteringText(filteringText)}
            filteringAriaLabel={`Filter ${props.searchFilters.entityType}`}
            filteringPlaceholder={`Search with prefix or full match`}
            filteringClearAriaLabel='Clear'
            countText={`${totalItems.length} ${totalItems.length === 1 ? 'match' : 'matches'}`}
          />
          <Button onClick={onLoadNextResults} disabled={!nextToken}>
            Load more results ...
          </Button>
        </Grid>
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={
        <CollectionPreferences
          title={'Preferences'}
          confirmLabel={'Confirm'}
          cancelLabel={'Cancel'}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={largePageSizePreference}
          wrapLinesPreference={defaultWrapLinesPreference}
        />
      }
    />
  );
};
